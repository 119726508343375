/* Grid background styles using CSS modules */
.animatedGrid {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: #121212;
  overflow: hidden;
}

.gridLine {
  position: absolute;
  background-color: rgba(94, 234, 212, 0.2); /* Turquoise with transparency */
}

.gridLineHorizontal {
  composes: gridLine;
  height: 1px;
  width: 100%;
}

.gridLineVertical {
  composes: gridLine;
  width: 1px;
  height: 100%;
}

/* Retro fade effect */
.boxFadeOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: radial-gradient(
    ellipse at center,
    rgba(18, 18, 18, 0) 0%,
    rgba(18, 18, 18, 0.8) 80%,
    rgba(18, 18, 18, 1) 100%
  );
}

/* Alternative simple grid using only CSS */
.boxBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: #121212; /* Dark background */
  background-image: linear-gradient(rgba(26, 26, 46, 0.8) 1px, transparent 1px),
    linear-gradient(90deg, rgba(26, 26, 46, 0.8) 1px, transparent 1px);
  background-size: 20px 20px; /* Size of each box */

  /* Optional: Add a subtle glow effect */
  box-shadow: inset 0 0 100px rgba(94, 234, 212, 0.1); /* Use your turquoise color */
}
