.workContainer {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
  width: screen;
}

.cardContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: #7689C680;
  border-radius: 20px;
  overflow: hidden;
  max-width: 1200px;
  padding: 20px;
}

.leftSide {
  display: flex;
  flex-direction: column;
  width: 30%;
  gap: 10px;
}

.rightSide {
  display: flex;
  flex-direction: column;
  width: 70%;
  background-color: #6477B3;
  height: max;
}

.buttonContainer {
  display: flex;
  background-color: #6477B3;
  padding: 15px;
  border-radius: 10px 0px 0px 10px;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.2s ease;
  
  &:hover {
    background-color: #5a6ba1;
  }
}

.buttonActive {
  background-color: #455283;
}

.buttonContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.expTitle {
  font-family: Jura;
  font-weight: 700;
  font-size: 16px;
  color: #fff;
  margin: 0 0 4px 0;
}

.expCompany {
  font-family: Jura;
  font-size: 14px;
  color: #ddd;
  margin: 0 0 2px 0;
}

.expPeriod {
  font-family: Jura;
  font-size: 12px;
  color: #ccc;
  margin: 0;
}

.boxHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 10px;
}

.leftBar, .rightBar {
  font-family: Jura;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  border-radius: 20px;
  width: 45%;
  cursor: pointer;
  transition: all 0.2s ease;
  color: #000;
  font-weight: 500;
  
  &:hover {
    opacity: 0.9;
  }
}

.leftBar, .rightBar {
  background-color: #AEC0FE; /* Default non-selected color */
  cursor: pointer;
  padding: 10px 15px; /* Add some padding for better appearance */
  transition: background-color 0.3s ease; /* Smooth transition between states */
}

/* Active tab style */
.activeTab {
  background-color: #7B99FD; /* Selected tab color */
  font-weight: 600;
}

.boxBody {
  background-color: #D9D9D9;
  flex: 1;
  padding: 15px;
  margin:20px;
  text-align: center;
  border-radius: 10px;
  
  p {
    font-family: Jura;
    line-height: 1.5;
    margin: 0;
  }
}

.headerWork1 {
  font-family: Jura;
  color: rgb(255, 255, 255);
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 20px;
}

@keyframes floatFlower {
  0% { transform: translateY(0px); }
  50% { transform: translateY(-15px); }
  100% { transform: translateY(0px); }
}

.backgroundImage2 {
  content: "";
  position: absolute;
  top: 1500px;
  right: 230px;
  bottom: 0;
  width: 154px;
  height: 156px;
  z-index: -1;
  animation: floatFlower 4s ease-in-out infinite;
  
  @media (max-width: 1024px) {
    right: 5px;
    top: 2200px;
    width: 133px;
    height: 119px;
  }
}

// Responsive adjustments
@media (max-width: 768px) {
  .cardContainer {
    flex-direction: column;
    padding: 15px;
  }
  
  .leftSide, .rightSide {
    width: 100%;
    border-radius: 10px;
  }
  
  .leftSide {
    margin-bottom: 15px;
  }
  
  .buttonContainer {
    border-radius: 10px;
    &:hover {
      transform: translateY(-2px);
    }
  }
  
  .buttonActive {
    border-left: none;
    border-bottom: 3px solid #AEC0FE;
    transform: translateY(-2px);
  }
}

/* Add these new styles to your existing CSS file */

/* Content text styling */
/* Update these styles in your Work.module.scss file */

/* Content text styling */
.contentText {
  font-family: Jura;
  line-height: 1.5;
  margin: 0;
  text-align: left;
}

/* Project content layout - changed from horizontal to vertical */
.projectContent {
  display: flex;
  flex-direction: column;
  text-align: left;
  height: 100%;
}

.projectImageContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

.projectImage {
  max-width: 100%;
  max-height: 250px;
  border-radius: 8px;
  object-fit: contain;
}

.workImageContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

.workImage {
  max-width: 100%;
  max-height: 250px;
  border-radius: 8px;
  object-fit: contain;
}

.projectTextContainer {
  width: 100%;
}

/* Tab styles - update to handle single tab case */
.boxHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 10px;
}

.leftBar, .rightBar {
  font-family: Jura;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.2s ease;
  color: #000;
  font-weight: 500;
  background-color: #AEC0FE; /* Default non-selected color */
  transition: background-color 0.3s ease;
  
  &:hover {
    opacity: 0.9;
  }
}

/* When there's only one tab (description only), make it full width */
.rightBar {
  width: 45%;
}

.leftBar {
  width: 45%;
}

/* Active tab style */
.activeTab {
  background-color: #7B99FD; /* Selected tab color */
  font-weight: 600;
}

/* Remove the existing .projectWithImage styles that are no longer needed */