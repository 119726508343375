.footerContainer {
  display: flex;
  width: screen;
  padding: 23px 52px;
  justify-content: space-between;
  height: 140px;
  align-items: flex-end;
  gap: 10px;
  border-radius: 50px 50px 0px 0px;
  background-color: #1E1E3F;
}

.footerLogo {
  width: 50px;
  height: 50px;
  transition: transform 0.3s ease;
}

.footerLogo:hover {
  transform: scale(1.05);
}

.footerLogoDownload {
  width: 20px;
  height: 20px;
}

.copyrightLogo {
  width: 10px;
  height: 10px;
}

.footerCopyrightContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  // background-color: #ffe9d2;
  // padding: 10px;
  // border-radius: 10px;
}

.footerMenu {
  display: flex;
  align-items: flex-end;
  gap: 36px;
  border-radius: 5px;
  padding: 5px;
  background-color: #71e4d2;
}

.footerHome {
  display: flex;
  height: 36px;
  padding: 1px 21px;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  border-radius: 12px;
  background: #80faeb;

  transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth transition for color change */

  cursor: pointer; /* Change cursor to hand on hover */
}

.footerDownload {
  padding-bottom: 50px;
}
.footerHome:hover {
  background: #93fffa; /* Change color on hover */
  transform: scale(1.05); /* Scale up by 5% on hover */
}

.footerTitle {
  color: var(--Purple, #611ff3);
  /* Body 1 */
  font-family: Jura;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @media (max-width: 1200px) {
    font-size: 18px;
  }
}

.footerCopyright {
  color: #fff;
  /* Body 1 */
  font-family: Jura;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.meTitle {
  color: var(--Purple, #611ff3);
  /* Body 1 */
  font-family: Jura;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 100px;
}

.navbarMe {
  display: flex;
  height: 34px;
  padding: 1px 21px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background: #71e4d2;
  transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth transition for color change */
  cursor: pointer; /* Change cursor to hand on hover */
}

.navbarMe:hover {
  background: #93fffa; /* Change color on hover */
  transform: scale(1.05); /* Scale up by 5% on hover */
}

.navbarProject {
  display: flex;
  height: 36px;
  padding: 1px 21px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background: #71e4d2;
  transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth transition for color change */
  cursor: pointer; /* Change cursor to hand on hover */
}
.navbarProject:hover {
  background: #93fffa; /* Change color on hover */
  transform: scale(1.05); /* Scale up by 5% on hover */
}

.navbarConnect {
  display: flex;
  height: 36px;
  padding: 1px 21px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background: #71e4d2;
  transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth transition for color change */
  cursor: pointer; /* Change cursor to hand on hover */
}

.navbarConnect:hover {
  background: #93fffa; /* Change color on hover */
  transform: scale(1.05); /* Scale up by 5% on hover */
}


@media (max-width: 1024px) {
  .footerMenu,
  .footerCopyrightContainer {
    display: none; /* Hide menu items below 750px */
  }

  .footerDownload {
    margin: 0 auto; /* Center the logo below 750px */
  }
}