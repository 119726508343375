// Updated Navbar.scss with hamburger menu styles
.navbarContainer {
  display: flex;
  width: screen;
  padding: 23px 52px;
  justify-content: space-between;
  align-items: flex-start;
  gap: 10px;
  border-radius: 0px 0px 50px 50px;
  background-color: #1E1E3F; // Dark purple-blue (retro computing feel)
  // or #2C2C54 (deeper purple-blue)
  // or #1A2C42 (dark teal-blue)
  color: #ffffff;
  position: relative; // Added for mobile menu positioning
  transition: border-radius 0.3s ease; // Add transition for smooth radius change
  
  @media (max-width: 750px) {
    padding: 23px 30px;
  }
  
  // Add this class for when menu is open
  &.menuOpen {
    @media (max-width: 750px) {
      border-radius: 0px;
    }
  }
}

// Also update the mobile menu to match the navbar when open
.mobilMenu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: #ffe9d2;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 0 0 20px 20px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  transform: translateY(-20px);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  z-index: 99;
  
  &.open {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
    border-radius: 0 0 0 0; // Remove bottom radius when open
  }
  
  @media (max-width: 750px) {
    display: flex;
  }
}

.navbarLogo {
  width: 50px;
  height: 46px;
}

.navbarMenu {
  display: flex;
  align-items: flex-end;
  gap: 36px;
  
  @media (max-width: 750px) {
    display: none; // Hide menu items below 750px
  }
}

// Hamburger menu icon
.hamburgerIcon {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 21px;
  cursor: pointer;
  z-index: 100;
  
  @media (max-width: 750px) {
    display: flex;
  }
}

.hamburgerLine {
  width: 100%;
  height: 3px;
  background-color: #611ff3;
  border-radius: 10px;
  transition: all 0.3s ease;
  
  &.open {
    &:nth-child(1) {
      transform: translateY(9px) rotate(45deg);
    }
    
    &:nth-child(2) {
      opacity: 0;
    }
    
    &:nth-child(3) {
      transform: translateY(-9px) rotate(-45deg);
    }
  }
}

// Mobile menu
.mobileMenu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: #ffe9d2;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 0 0 20px 20px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  transform: translateY(-20px);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  z-index: 99;
  
  &.open {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
  }
  
  @media (max-width: 750px) {
    display: flex;
  }
}

.mobileMenuItem {
  width: 90%;
  padding: 15px;
  margin: 5px 0;
  border-radius: 12px;
  background: #71e4d2;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  
  &:hover {
    background: #93fffa;
    transform: scale(1.03);
  }
  
  p {
    color: #611ff3;
    font-family: Jura;
    font-size: 18px;
    font-weight: 700;
    margin: 0;
  }
}

// Original button styles
.navbarHome, .navbarMe, .navbarProject, .navbarConnect {
  display: flex;
  height: 36px;
  padding: 1px 21px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background: #71e4d2;
  transition: background-color 0.3s ease, transform 0.3s ease;
  cursor: pointer;
  
  &:hover {
    background: #93fffa;
    transform: scale(1.05);
  }
}

.navbarMe {
  height: 34px;
}

.homeTitle, .meTitle {
  color: #611ff3;
  font-family: Jura;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.meTitle {
  width: 100px;
}