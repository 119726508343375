.aboutMeContainer {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
}

/* Use a more specific name for the keyframes */
@keyframes floatSun {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-15px);
  }
  100% {
    transform: translateY(0px);
  }
}

.backgroundImage {
  content: "";
  position: absolute;
  top: 600px;
  left: 270px;
  right: 0;
  bottom: 0px;
  width: 174px;
  height: 156px;
  z-index: 10;
  animation: floatSun 4s ease-in-out infinite;

  @media (max-width: 1024px) {
    left: 5px;
    top: 800px;
    width: 133px;
    height: 119px;
  }
}

.helloHeading1AboutMe {
  color: rgb(255, 255, 255);

  /* Title 1 */
  font-family: Jura;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 0.1;
  align-items: center;
}

.box {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
}

.leftBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 391.192px;
  height: 570.32px;
  background: #e3868e;
  justify-content: center;
  align-items: center;
  border: 3px solid rgb(0, 0, 0);
}

.leftFill {
  display: flex;
  width: 70%;
  height: 70%;
  padding: 24px 27px;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  background: #fdc21e;
  border: 2px solid rgb(0, 0, 0);
  border-radius: 12px;
}

.body1 {
  color: rgb(0, 0, 0);

  /* Body 1 */
  font-family: Jura;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: start;
  margin-top: 0;
}

.rightBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 391.192px;
  height: 570.32px;
  background: #017f7e;
  justify-content: center;
  align-items: center;
  border: 3px solid rgb(0, 0, 0);
  // border-radius: 12px;
}

.rightFill {
  display: flex;
  flex-direction: column;
  width: 70%;
  height: 70%;
  padding: 24px 27px;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  background: #a4f3de;
  border-radius: 12px;
  border: 2px solid rgb(0, 0, 0);
}

.imageContainerMe {
  width: 273px;
  height: 383px;
  stroke-width: 1px;
  stroke: #000;
  border-radius: 12px;
  animation: none;
}

.buttonContainerMe {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 12px;
  margin-left: 10px;
  transition: transform 0.3s ease;
}

.buttonMe {
  width: 26px;
  height: 25px;
  border-radius: 100px;
  background: #ef81a4;
  transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth transition for color change */
  cursor: pointer;
}

.buttonMe:hover {
  background: #ecb4c6;
  transform: scale(1.05);
}

.footerMenu {
  display: flex;
  align-items: flex-end;
  gap: 36px;
  padding: 20px;
  border-radius: 10px;
  background-color: #ecb4c6;
}

.footerLogo {
  width: 50px;
  height: 50px;
  transition: transform 0.3s ease;
}

.footerLogo:hover {
  transform: scale(1.05);
}
